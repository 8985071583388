<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Centro de costos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @setFilters="setFilters"
                        @loadModalData="loadModalData"
                        :showAdvancedFilters="true"
                        :perPage="[10,25,50,100]"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar" >
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                />
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                    <v-text-field 
                                        outlined
                                        label="Clave" 
                                        v-model="centroCosto.clave" 
                                        class="pa-0 ma-0" 
                                    >
                                    </v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-text-field 
                                    outlined
                                    label="Descripción" 
                                    v-model="centroCosto.descripcion" 
                                    class="pa-0 ma-0" 
                                >    
                                </v-text-field>
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td><div>{{item.clave}}</div></td>
                                <td><div>{{item.descripcion}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark @click="abrirModal('update', item)" small>
                                            <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                            <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                        </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-dialog v-model="dialog" persistent max-width="600px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal" style="display: none;"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div class="d-flex justify-center my-3" v-if="isLoading">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-else="!isLoading" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Cliente"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Empresa"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresa_value"
                                                required 
                                                :error-messages="errors" 
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Clave" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Clave" 
                                                v-model="centroCosto.clave" 
                                                class="pa-0 ma-0" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                        <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Descripción" 
                                                v-model="centroCosto.descripcion" 
                                                class="pa-0 ma-0" 
                                                :error-messages="errors" 
                                                required
                                            >    
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import apiCentroCostos from "../api/nominas/centroCostos";
import apiClientes from "../api/clientes";
import empresaApi from '@/api/empresas';
import Datatable from '@/components/datatable/Datatable.vue';
import Notify from '@/plugins/notify';

export default {
    components: {
        'data-table': Datatable,
    },
    watch: {
        cliente_value: function(val){
            this.getEmpresas(val);
        },
    },
    data() {
        return {
            url         : "centro-de-costos/find",
            columns     : [
                {
                    label: 'Clave',
                    name: 'clave',
                    filterable: true
                },
                {
                    label: 'Descripción',
                    name: 'descripcion',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align: 'center',
                    filterable: false
                }
            ],
            filters     : {
                activo      : true,
                paginate    : true,
                cliente_id  : null,
                empresa_id  : null,
                clave       : null,
                descripcion : null,
            },
            clientes        : [],
            empresas        : [],
            centroCosto : {
                id              : null,
                cliente_id      : null,
                empresa_id      : null,
                clave           : null,
                descripcion     : null,
                
            },
            cliente_value       : null,
            empresa_value       : null,
            userData            : this.$session.get('usuario'),
            accion              : null,
            tituloModal         : null,
            dialog              : false,
            isLoading           : false,
            isSaving            : false,
            imagen              : "",
            rol                 : "",
            datosLogin          : null,
        }
    },
    methods: {
        async getClientes() {
            let params = {
                activo      : true,
                paginate    : false,
            };

            await apiClientes.getClientes(params).then(response => {
                this.clientes   = response.data;
            })
            .catch(error => {
                console.error(error);
            });
        },
        getEmpresas(id) {
            if(this.centroCosto.cliente_id != id){
                this.empresa_value = null;
                this.centroCosto.empresa_id = null;
            }

            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response)=>{
                this.empresas = response.data;
                if(this.centroCosto.empresa_id != null){
                    this.empresa_value = this.centroCosto.empresa_id;
                }
            })
            .catch(error => {
                this.resetValues();
                Notify.ErrorToast(error.response.data.error);
            });
        },
        
        async abrirModal(accion, data = null) {
            this.accion     = accion;
            this.isLoading  = true;
            await this.resetValues();

            if(accion == "add") {
                this.tituloModal    = "Registro Centro de Costos";
                this.imagen         = "/static/modal/centroCostosCreate.svg";
                if (this.rol == "root") {
                    this.getClientes();
                }
                else if (this.rol == "admin") {
                    this.cliente_value = this.datosLogin.cliente_id;
                }
                else {
                    this.cliente_value = this.datosLogin.cliente_id;
                    this.empresa_value = this.datosLogin.empresa_id;
                }
                
            } else {
                this.tituloModal                = "Actualizar Centro de Costos";
                this.imagen                     = "/static/modal/centroCostosUpdate.svg";
                this.centroCosto.id             = data.id;
                this.centroCosto.cliente_id     = data.cliente_id;
                this.centroCosto.empresa_id     = data.empresa_id;
                this.cliente_value              = data.cliente_id;
                this.empresa_value              = data.empresa_id;
                this.centroCosto.clave          = data.clave;
                this.centroCosto.descripcion    = data.descripcion;
            }
            document.getElementById("btnModal").click();
            this.isLoading  = false;
            
        },
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        async resetValues() {
            this.centroCosto = {
                id              : null,
                cliente_id      : null,
                empresa_id      : null,
                clave           : null,
                descripcion     : null,
            };
            this.cliente_value  = null;
            this.empresa_value  = null;
            this.$nextTick(() => {
                if(this.$refs.form){
                    this.$refs.form.reset();
                }
            });
        },
        guardar() {
            this.$refs.form.validate().then(success => {
            this.isSaving = true;
            if(!success) {
                this.isSaving = false;
                return;
            }

            this.centroCosto.cliente_id = this.cliente_value;
            this.centroCosto.empresa_id = this.empresa_value;

            if(this.accion === "add") {
                apiCentroCostos.store(this.centroCosto)
                .then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "El registro centro de costo se guardó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.accion === "update") {
                apiCentroCostos.update(this.centroCosto)
                .then((response) => {
                    this.$refs.tabla.getData();
                    Notify.Success("Operación exitosa", "El registro de centro de costo se actualizó satisfactoriamente.");
                    this.cerrarModal();
                    this.$nextTick(() => {
                        this.$refs.form.reset();
                    });
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            });
        },
        eliminar(data) {
            Notify.Alert(
                "¿Estás seguro de eliminar el centro de costo?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    apiCentroCostos.delete(data.id).then(response => {
                        this.$refs.tabla.getData();
                        Notify.Success("Operación exitosa", "El registro de centro de costo se elimino satisfactoriamente.");
                    })
                    .catch(err => {
                        console.error(err);
                        Notify.ErrorToast(err.response.data.error);
                    });
                }
            )
        },
        async loadModalData(){
            await this.resetValues()
            if(this.rol == 'root'){
                await this.getClientes();
            }
            else if(this.rol == 'admin'){
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            
        },
        async setFilters(){
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let descripcion = this.centroCosto.descripcion;
            let clave = this.centroCosto.clave;
            if(this.rol == 'root'){

            }
            else if (this.rol == "admin") {    
                cliente = this.datosLogin.cliente_id;
            }
            else{
                cliente = this.datosLogin.cliente_id;
                empresa= this.datosLogin.empresa_id;
            }


            this.filters.cliente_id     = cliente;
            this.filters.empresa_id     = empresa;
            this.filters.descripcion    = descripcion; 
            this.filters.clave          = clave; 

            await this.$refs.tabla.getData();
            await this.resetValues();
        },
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if(this.rol == 'root'){

            }
            else if(this.rol == 'admin'){
                this.filters.cliente_id = this.datosLogin.cliente_id;
            }
            else{
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
            }
        },
    },
    created() {
        this.init();
    },
}
</script>

<style scoped>
</style>

